<div *ngIf="formComponent"
  class="gc-well"
  cdkDropList
  [cdkDropListSortingDisabled]="false"
  [class.editable]="editable"
  [cdkDropListDisabled]="!editable"
  [cdkDropListData]="formComponent.components"
  (cdkDropListDropped)="handleDropEvent($event)">

  <!-- No components -->
  <gc-form-builder-empty-container *ngIf="editable && !formComponent.components?.length">
  </gc-form-builder-empty-container>

  <div *ngFor="let component of formComponent.components; trackBy: trackBy"
    cdkDrag
    [cdkDragData]="component"
    [class.mb-4]="!component.isHidden && !component.hiddenFromParent"
    class="form-component-drag">

    <gc-form-builder-drag-preview
      [component]="component">
    </gc-form-builder-drag-preview>

    <gc-form-component-display
      [depth]="depth"
      [formGroup]="formGroup"
      [editable]="editable"
      [translations]="translations"
      [isFormBuilderView]="isFormBuilderView"
      [component]="component | ycAs: $baseComponent"
      [notAutoSave]="notAutoSave"
      [isManagerEditingApplicantForm]="isManagerEditingApplicantForm"
      [isManagerForm]="isManagerForm"
      [inConfigModal]="inConfigModal"
      [formId]="parentFields.formId"
      [parentFields]="parentFields"
      [showErrorSummary]="showErrorSummary"
      [errorMessages]="errorMessages"
      [masked]="masked"
      [disabledOverride]="disabledOverride"
      [value]="component.value"
      [refIdsChanged]="refIdsChanged"
      [standardFieldsChanged]="standardFieldsChanged"
      (componentDropped)="handleDropEvent($event)"
      (onValueChange)="valueChanged($event, component)"
      (componentActionClick)="componentActionClick.emit($event)">
    </gc-form-component-display>
  </div>
</div>
