<div *ngFor="let rowComponents of formComponent.rows"
  class="d-flex flex-grow-1 row-border">
  <div *ngFor="let componentObj of rowComponents; let last = last"
    [ngStyle]="{
      'width': (100 / formComponent.rows.length) + '%'
    }"
    [class.me-3]="!last"
    [class.editable]="editable"
    cdkDropList
    [cdkDropListSortingDisabled]="false"
    [cdkDropListDisabled]="!editable"
    [cdkDropListData]="componentObj.components"
    (cdkDropListDropped)="handleDropEvent($event)">
    
    <gc-form-builder-empty-container *ngIf="editable &&
      !componentObj.components?.length">
    </gc-form-builder-empty-container>

    <div *ngFor="let component of componentObj.components trackBy: trackBy"
      cdkDrag
      [cdkDragData]="component"
      [class.me-2]="!last"
      class="form-component-drag">
      
      <gc-form-builder-drag-preview
        [component]="component">
      </gc-form-builder-drag-preview>

      <gc-form-component-display
        [depth]="depth"
        [formGroup]="formGroup"
        [editable]="editable"
        [component]="component | ycAs: $baseComponent"
        [notAutoSave]="notAutoSave"
        [formId]="parentFields.formId"
        [value]="component.value"
        [inConfigModal]="inConfigModal"
        [disabledOverride]="disabledOverride"
        [translations]="translations"
        [isManagerEditingApplicantForm]="isManagerEditingApplicantForm"
        [isManagerForm]="isManagerForm"
        [masked]="masked"
        [showErrorSummary]="showErrorSummary"
        [errorMessages]="errorMessages"
        [refIdsChanged]="refIdsChanged"
        [isFormBuilderView]="isFormBuilderView"
        [standardFieldsChanged]="standardFieldsChanged"
        [parentFields]="parentFields"
        (onValueChange)="valueChanged($event, component)"
        (componentDropped)="handleDropEvent($event)"
        (componentActionClick)="componentActionClick.emit($event)">
      </gc-form-component-display>
    </div>
  </div>
</div>
