import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { I18nService } from '@yourcause/common/i18n';

@Component({
  selector: 'gc-form-builder-drag-preview',
  templateUrl: './form-builder-drag-preview.component.html',
  styleUrls: ['./form-builder-drag-preview.component.scss']
})
export class FormBuilderDragPreviewComponent implements OnInit, OnChanges{
  @Input() component: FormDefinitionComponent;

  label: string;
  afterInit = false;

  constructor (
    private i18n: I18nService
  ) { }

  ngOnInit () {
    this.setLabel();
    this.afterInit = true;
  }

  ngOnChanges (changes: SimpleChanges) {
    if (this.afterInit && changes.component) {
      this.setLabel();
    }
  }

  setLabel () {
    this.label = this.component.legend ||
      this.component.title ||
      this.component.label ||
      this.i18n.translate('common:textDraggingComponent', {}, 'Dragging component');
  }
}
